import axiosIns from '@/libs/axios'

export default class AuthApis {
    getUserInfo = async () => await axiosIns.get('/profile')

    login = async data => await axiosIns.post('login', data)

    logout = async () => await axiosIns.post('logout').then(res => {
      localStorage.removeItem('bank-ranodm-index')
      return res
    })

    register = async data => await axiosIns.post('register', data)

    forgotPassword = async data => await axiosIns.post('reset-password', data)

    resetPassword = async data => await axiosIns.post('change-password', data)

    resendCode = async data => await axiosIns.post('two-f-a/resend', data)

    loginSection2 = async data => await axiosIns.post('two-f-a/verify', data)

    sendOtp = async data => await axiosIns.post('register/otp/send', data)
}
