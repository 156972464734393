import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueExpandableImage from 'vue-expandable-image'
import VueFileAgent from 'vue-file-agent'
import moment from 'moment'
import i18n from '@/libs/i18n'
import '@/libs/yektanet'
import router from './router'
import store from './store'
import App from './App.vue'
import '@/libs/sweet-alerts'
import '@/libs/vue-cookies'
import '@/libs/vue-sse'
import '@/libs/bootstrap-vue-icon'
import '@/libs/vue-observe-visibility'
import { version } from '../package.json'
import './global-components'
import '@/libs/portal-vue'
import '@/libs/toastification'
import middleware from './router/middleware'
import '@/fake-db/db'
import { brandName } from './mixins/brandName'
import '@validations'

Vue.config.productionTip = false
// eslint-disable-next-line consistent-return
Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD')
  }
})
Vue.use(VueFileAgent)

Vue.use(VueExpandableImage)
require('@core/assets/fonts/feather/iconfont.css')
require('vue-file-agent/dist/vue-file-agent.css')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin(brandName)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

console.log(version)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  middleware,
  render: h => h(App),
}).$mount('#app')
