import Decimal from 'decimal.js'

// eslint-disable-next-line import/prefer-default-export
export function toEnglishDigits(str) {
  const e = '۰'.charCodeAt(0)
  const newSTR = str.toString().replace(/[۰-۹]/g, t => (t.charCodeAt(0) - e))
  return newSTR
}

// function that get number and comma separate it
export function numberWithCommas(number) {
  // Convert the number to a string
  const numStr = number.toString()

  // Split the string into integer and decimal parts (if applicable)
  const parts = numStr.split('.')

  // Add commas to the integer part
  parts[0] = parts[0].replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Join the integer and decimal parts with a dot (.)
  const formattedNumber = parts.join('.')

  return formattedNumber
}
// exp

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

const defaultImageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024

export function resizeImage(image, targetSize = defaultImageSize) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let blob
    let flag = true

    img.onload = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      let { width } = img
      let { height } = img

      let currentSize = image.size
      let scaleFactor = 1

      do {
        scaleFactor = 0.5 // Adjust the scale factor as desired

        width *= scaleFactor
        height *= scaleFactor

        canvas.width = width
        canvas.height = height

        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(img, 0, 0, width, height)

        const dataUrl = canvas.toDataURL('image/jpeg', 70)
        blob = dataURItoBlob(dataUrl)
        currentSize = blob.size

        if (currentSize > targetSize) {
          flag = true
        } else {
          flag = false
        }
      } while (flag)
      return resolve(new File([blob], 'imageResized.jpg', {
        type: 'image/jpeg',
      }))
    }

    img.onerror = error => {
      reject(error)
    }

    img.src = URL.createObjectURL(image)
  })
}

/**
 *
 * @param num float or number
 * @param decimals decimals point default 8
 * @returns string float
 */
export function toFixed(num, decimals) {
  // eslint-disable-next-line no-nested-ternary, radix
  const checkedDecimal = typeof decimals === 'number' ? decimals : (Number.isNaN(parseInt(decimals)) ? 8 : parseInt(decimals))
  const d = new Decimal(num)
  const result = d.toFixed(checkedDecimal, Decimal.ROUND_DOWN)
  return result
}

export function getCookieByName(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 && parts.pop().split(';').shift()
}

export function convertSecondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60) // Calculate full minutes
  const remainingSeconds = seconds % 60 // Get the remaining seconds after minutes

  // Return formatted time
  return `${minutes}:${remainingSeconds}`
}
