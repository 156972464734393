import {
  DollarSignIcon, ActivityIcon,
} from 'vue-feather-icons'

export default [
  {
    title: 'invest',
    icon: DollarSignIcon,
    route: 'investModule-plans',
    children: [
      {
        title: 'my-plan',
        route: 'investModule-my-plan',
        icon: ActivityIcon,
      },
      {
        title: 'plans',
        route: 'investModule-plans',
        icon: ActivityIcon,
      },
      {
        title: 'deposit',
        route: 'investModule-select-plan-type',
        icon: ActivityIcon,
      },
    ],
  },
]
