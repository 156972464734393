import axiosIns from '@/libs/axios'

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
}

export default class MessagingApis {
  async getAllMessages(data) {
    return axiosIns.get(`${process.env.VUE_APP_BASE_MESSAGING_URL}message/list?page=${data.currentPage}&pageSize=${data.pageSize}`, config)
  }

  async getMessageById(id) {
    return axiosIns.get(`${process.env.VUE_APP_BASE_MESSAGING_URL}message/${id}`, config)
  }

  async getUnreadMessage(messageCount) {
    return axiosIns.get(`${process.env.VUE_APP_BASE_MESSAGING_URL}message/unread-messages?message_count=${messageCount}`, config)
  }
}
