import mock from '@/fake-db/mock'

const data = {
  withdrawHistory: [
    {
      photo: 'btc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '564545454',
      status: 'pending',
      date: '2020-12-01',
    },
    {
      photo: 'itc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '564545454',
      status: 'pending',
      date: '2020-12-01',
    },
    {
      photo: 'uni',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '564545454',
      status: 'pending',
      date: '2020-12-01',
    },
    {
      photo: 'itc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '564545454',
      status: 'pending',
      date: '2020-12-01',
    },
    {
      photo: 'omg',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '564545454',
      status: 'pending',
      date: '2020-12-01',
    },
  ],
}

mock.onGet('withdraw').reply(() => [200, data])
