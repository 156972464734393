<template>

  <div>
    <b-modal
      id="modal-no-backdrop"
      ref="myModal"
      content-class="shadow"
      no-close-on-backdrop
      hide-header
    >
      <p class="mt-1">
        {{ $t('notice_text.traders') }}
      </p>
      <p class="my-2 text-justify">
        {{ $t('notice_text.description') }}
      </p>
      <p>{{ $t('notice_text.thanks') }}</p>
      <template #modal-footer="{ ok }">
        <b-button
          size="sm"
          variant="success"
          @click="ok()"
        >
          {{ $t('notice_text.btn') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  computed: {
    SHOW_SYSTEM_UPDATE_ALERT() {
      return process.env.VUE_APP_SHOW_SYSTEM_UPDATE_ALERT === 'true'
    },
  },
  mounted() {
    if (this.SHOW_SYSTEM_UPDATE_ALERT) {
      const lastShown = localStorage.getItem('showAlert')
      console.log(moment(lastShown).valueOf(), moment(new Date()).valueOf(), moment(lastShown).valueOf() <= moment(new Date()).valueOf())
      if (lastShown) {
        if (moment(lastShown).valueOf() + 24 * 60 * 60 * 100 <= moment(new Date()).valueOf()) {
          this.$refs.myModal.show()
          localStorage.setItem('showAlert', new Date())
        }
      } else {
        this.$refs.myModal.show()
        localStorage.setItem('showAlert', new Date())
      }
    }
  },
}
</script>
