/* eslint-disable */
if ( process.env.VUE_APP_YEKTANET_CODE) {
    !(function (t, e, n) {
        t.yektanetAnalyticsObject = n, t[n] = t[n] || function () {
            t[n].q.push(arguments)
        }, t[n].q = t[n].q || []
        const a = new Date()
        const r = `${a.getFullYear().toString()}0${a.getMonth()}0${a.getDate()}0${a.getHours()}`
        const c = e.getElementsByTagName('head')[0]
        const s = e.createElement('script')
        s.id = process.env.VUE_APP_YEKTANET_CODE; s.dataset.analyticsobject = n
        s.async = 1; s.type = 'text/javascript'
        s.src = `https://cdn.yektanet.com/rg_woebegone/scripts_v3/fV0qil3L/rg.complete.js?v=${r}`, c.appendChild(s, c)
    }(window, document, 'yektanet'))
}
