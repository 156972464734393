export default [
  {
    path: '/:lang/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    children: [
      {
        path: 'general',
        name: 'profile-general',
        component: () => import('@/views/profile/General.vue'),
        meta: {
          pageTitle: 'general',
          middleware: 'auth',
          navActiveLink: 'profile-general',
          breadcrumb: [
            {
              text: 'Profile',
              active: true,
            },
          ],
        },
      },
      {
        path: 'security',
        name: 'profile-security',
        component: () => import('@/views/profile/Security.vue'),
        meta: {
          pageTitle: 'security',
          middleware: 'auth',
          navActiveLink: 'profile-general',
          breadcrumb: [
            {
              text: 'Profile',
              active: true,
            },
          ],
        },
      },
      {
        path: 'notification',
        name: 'profile-notification',
        component: () => import('@/views/profile/Notification.vue'),
        meta: {
          pageTitle: 'notification',
          middleware: 'auth',
          navActiveLink: 'profile-general',
          breadcrumb: [
            {
              text: 'Profile',
              active: true,
            },
          ],
        },
      },
      {
        path: 'referral',
        name: 'profile-referral',
        component: () => import('@/views/profile/referral.vue'),
        meta: {
          pageTitle: 'referral',
          middleware: 'auth',
          navActiveLink: 'profile-general',
          breadcrumb: [
            {
              text: 'Profile',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: 'set-pin',
    name: 'set-pin',
    component: () => import('@/views/profile/SetPin.vue'),
    meta: {
      layout: 'full',
      middleware: 'auth',
      pageTitle: 'SetPin',
      breadcrumb: [
        {
          text: 'SetPin',
          active: true,
        },
      ],
    },
  },
  {
    path: 'token-expired',
    name: 'token-expired',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: 'delete-account',
    name: 'delete-account',
    component: () => import('@/views/profile/deleteAccount.vue'),
    meta: {
      pageTitle: 'delete_your_account',
      middleware: 'auth',
      navActiveLink: 'delete-account',
      breadcrumb: [
        {
          text: 'delete_your_account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/profile/register-questions',
    name: 'register-questions',
    component: () => import('@/views/profile/questionForm.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'profile',
      // middleware: 'geust',
      // navActiveLink: 'profile-general',
      breadcrumb: [
        {
          text: 'register-questions',
          active: true,
        },
      ],
    },
  },
]
