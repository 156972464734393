import mock from '@/fake-db/mock'

const data = {
  transactionHistory: [
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
    {
      price: '515484848451',
      quantity: '95951515',
      date: '2022-02-01',
      name: 'Bitcoin',
      commission: '598848454',
    },
  ],
}

mock.onGet('transaction-history').reply(() => [200, data])
