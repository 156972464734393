// in main.js
import VueSSE from 'vue-sse'
import Vue from 'vue'

Vue.use(VueSSE, {
  url: `${process.env.VUE_APP_BASE_MESSAGING_URL}message/cabin-message`,
  polyfill: true,
  forcePolyfill: true,
  polyfillOptions: {
    heartbeatTimeout: 20000000,
    headers: {
      authorization: localStorage.getItem('token'),
    },
  },
})
