import {
  CopyIcon, ActivityIcon,
} from 'vue-feather-icons'

export default [
  // {
  //   title: 'Bonus',
  //   icon: CopyIcon,
  //   route: 'Bonus',
  //   children: [
  //     {
  //       title: 'bonusHistory',
  //       route: 'bonusModule-history',
  //       icon: ActivityIcon,
  //     },
  //   ],
  // },
]
