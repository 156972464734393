export default [
  {
    path: '/:lang/bonus/history',
    name: 'bonusModule-history',
    component: () => import('@/modules/bonus/views/history.vue'),
    meta: {
      navActiveLink: 'Bonus',
      pageTitle: 'Bonus',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'bonus',
          active: false,
        },
        {
          text: 'bonus-history',
          active: true,
          to: {
            name: 'bonusModule-history',
          },
        },
      ],
    },
  },
]
