// eslint-disable-next-line import/no-cycle
import AuthApis from '@/services/apis/auth'
// eslint-disable-next-line import/no-cycle
import { setLang } from '@/auth/utils'
import ProfileApis from '@/services/apis/profile'

const auth = new AuthApis()
const profile = new ProfileApis()
export default {
  state: {
    errors: [],
    authenticated: false,
    user: {},
    token: false,
    email: '',
    expireTime: '',
    fullName: '',
    citizenship: '',
    maxUploadSize: '',
    globalLoading: false,
    isProfileCalled: false,
    documentStatus: null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    getCitizenship(state) {
      return state.citizenship
    },
    getMaxUploadSize(state) {
      return state.maxUploadSize
    },
  },
  mutations: {
    STORE_ERRORS: (state, error) => {
      // Call Error Service here
      // ErrorService.onError(error)
      // ErrorService.initHandler()
      // Store error to state(optional)
      if (error.response) {
        state.errors = error.response
      }
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
      localStorage.setItem('authenticated', value)
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_IS_PROFILE_CALLED(state, value) {
      state.isProfileCalled = value
    },
    SET_FULL_NAME(state, value) {
      state.fullName = value
      localStorage.setItem('fullName', value)
    },
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    SET_EMAIL(state, email) {
      state.email = email
      localStorage.setItem('email', email)
    },
    SET_EXPIRE_TIME(state, expireTime) {
      state.expireTime = expireTime
      localStorage.setItem('expireTime', expireTime)
    },
    SET_CITIZENSHIP(state, citizenship) {
      state.citizenship = citizenship
    },
    SET_MAX_UPLOAD_SIZE(state, maxUploadSize) {
      state.maxUploadSize = maxUploadSize
    },
    TOGGLE_LOADING(state) {
      state.globalLoading = !state.globalLoading
    },
    SET_DOCUMENT_STATUS(state, documentStatus) {
      state.documentStatus = documentStatus
    },
  },
  actions: {
    async login({ commit }, data) {
      try {
        const response = await auth.login(data)
        if (!response.data.results.has_two_factor_auth) {
          profile.get()
        }
        if (response.status === 200) {
          commit('SET_CITIZENSHIP', response.data.citizenship)
          commit('SET_MAX_UPLOAD_SIZE', response.data.max_upload_size)
          if (response.data.results.has_two_factor_auth) {
            commit('SET_EMAIL', response.data.results.email)
            commit('SET_EXPIRE_TIME', response.data.results.expiration_time)
            setLang(response.data.results.language)
            return { status: 200, type: 'has Two Factor', lang: response.data.results.language }
          }
          commit('SET_DOCUMENT_STATUS', { ...response.data.results.document, profile_complete: response.data.results.profile_complete })
          commit('SET_USER', response.data.results.user)
          commit('SET_AUTHENTICATED', true)
          commit('SET_TOKEN', response.data.results.token)
          commit('SET_FULL_NAME', `${response.data.results.user.first_name} ${response.data.results.user.last_name}`)
          setLang(response.data.results.user.language)
          return {
            status: 200, type: 'login', lang: response.data.results.user.language,
          }
        }
        return response
      } catch (error) {
        commit('SET_USER', {})
        commit('SET_AUTHENTICATED', false)
        localStorage.removeItem('authenticated')
        // Handling HTTPs Errors
        commit('STORE_ERRORS', error)
        return error
      }
    },
    async logout({ commit }) {
      try {
        commit('TOGGLE_LOADING')
        const response = await auth.logout()
        if (response.status === 200) {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', false)
          localStorage.removeItem('authenticated')
          localStorage.removeItem('token')
        }
        commit('TOGGLE_LOADING')
        return response
      } catch (e) {
        return e
      }
    },
    async loginSection2({ commit }, data) {
      return new Promise((resolve, reject) => {
        auth.loginSection2(data).then(response => {
          if (response.status === 200) {
            commit('SET_USER', response.data.results.user)
            commit('SET_AUTHENTICATED', true)
            commit('SET_TOKEN', response.data.results.token)
            localStorage.removeItem('email')
            localStorage.removeItem('expireTime')
          }
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
