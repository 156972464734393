import MessagingApis from '@/services/apis/messaging'

const Messaging = new MessagingApis()

export default {
  namespaced: true,
  state: {
    modalShow: false,
    messages: [],
    totalMessages: 0,
    selectedMessage: {},
    unReadMessages: [],
    totalUnreadMessages: 0,
    event: '',
  },

  mutations: {
    TOGGLE_MODAL_SHOW(state) {
      state.modalShow = !state.modalShow
    },
    SET_SELECTED_MESSAGE(state, data) {
      state.selectedMessage = data
    },
    SET_UNREAD_MESSAGE(state, data) {
      state.unReadMessages = data.messages
      state.totalUnreadMessages = data.total
    },
    SET_ALL_MESSAGE(state, data) {
      state.messages = data.messages
      state.totalMessages = data.total
    },
    SET_EVENT(state, data) {
      state.event = data
    },
  },
  actions: {
    async openMessageModal({ commit, dispatch }, id) {
      commit('TOGGLE_LOADING', null, { root: true })
      const response = await Messaging.getMessageById(id)
      if (response.status === 200) {
        commit('TOGGLE_MODAL_SHOW')
        commit('SET_SELECTED_MESSAGE', response.data.data)
        dispatch('setUnreadMessages')
      }
      commit('TOGGLE_LOADING', null, { root: true })
      return response
    },

    async setUnreadMessages({ commit }) {
      const response = await Messaging.getUnreadMessage(3)
      if (response.status === 200) {
        commit('SET_UNREAD_MESSAGE', response.data.data)
      }
    },

    async setAllMessages({ commit }, data) {
      const response = await Messaging.getAllMessages(data)
      if (response.status === 200) {
        commit('SET_ALL_MESSAGE', response.data.data)
      }
    },

    async setEvent({ commit }, data) {
      commit('SET_EVENT', data)
    },
  },
}
