export default [
  {
    path: '/:lang/tickets',
    name: 'tickets',
    component: () => import('@/views/tickets/Tickets.vue'),
    meta: {
      navActiveLink: 'tickets',
      pageTitle: 'Tickets',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Tickets',
          active: true,
          to: {
            name: 'tickets',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/tickets/create',
    name: 'tickets-create',
    component: () => import('@/views/tickets/CreateTicket.vue'),
    meta: {
      navActiveLink: 'tickets',
      pageTitle: 'create_ticket',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Tickets',
          active: false,
          to: {
            name: 'tickets',
          },
        },
        {
          text: 'create_ticket',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/tickets/:id',
    name: 'ticket',
    component: () => import('@/views/tickets/Ticket.vue'),
    meta: {
      navActiveLink: 'tickets',
      pageTitle: 'ticket',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Tickets',
          active: false,
          to: {
            name: 'tickets',
          },
        },
        {
          text: 'Ticket',
          active: true,
        },
      ],
    },
  },
]
