import { ArrowLeftIcon, GlobeIcon } from 'vue-feather-icons'

console.log(process.env.VUE_APP_FOREX_TOOLS_ENABLE)
export default [
  {
    title: 'accounts-operations',
    icon: GlobeIcon,
    children: [
      {
        title: 'Metatrader',
        route: 'forexModule-accounts',
        icon: ArrowLeftIcon,
      },
      {
        title: 'deposit',
        route: 'forexModule-deposit',
        icon: ArrowLeftIcon,
      },
      {
        title: 'withdraw-title',
        route: 'forexModule-withdrawal',
        icon: ArrowLeftIcon,
      },
      (process.env.VUE_APP_FOREX_TOOLS_ENABLE === 'true' && {
        title: 'tools',
        route: 'forexModule-tools-list',
        icon: ArrowLeftIcon,
      }),
    ],
  },
]
