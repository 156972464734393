import mock from '@/fake-db/mock'

const data = {
  cryptoWallets: [
    {
      photo: 'btc',
      name: 'Bitcoin',
      balance: '598848454',
    },
    {
      photo: 'itc',
      name: 'Bitcoin',
      balance: '598848454',
    },
    {
      photo: 'uni',
      name: 'Bitcoin',
      balance: '598848454',
    },
    {
      photo: 'omg',
      name: 'Bitcoin',
      balance: '598848454',
    },
  ],
  CryptoTransferOption: [
    {
      photo: 'btc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '5487878.848484848334',
    },
    {
      photo: 'etc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
    {
      photo: 'itc',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
    {
      photo: 'omg',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
    {
      photo: 'ont',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
    {
      photo: 'trx',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
    {
      photo: 'uni',
      name: 'Bitcoin',
      abbreviation: 'BTC',
      price: '99855154',
    },
  ],
}

mock.onGet('customer-wallet').reply(() => [200, data])
