import { getBrandName } from '@/libs/helper'

// eslint-disable-next-line import/prefer-default-export
export const brandName = {
  methods: {
    getBrandName() {
      let bn
      if (this.$i18n) {
        bn = getBrandName(this.$i18n.locale)
      }
      return bn
    },
  },
}
