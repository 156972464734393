export default [
  {
    path: '/:lang/copytrade/create',
    name: 'copytrade_create',
    component: () => import('@/modules/copytrade/views/create.vue'),
    meta: {
      navActiveLink: 'copytrade',
      pageTitle: 'CopyTrade',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'CopyTrade',
          active: false,
        },
        {
          text: 'account_create',
          active: true,
          to: {
            name: 'copytrade_create',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/copytrade/list',
    name: 'copytrade_list',
    component: () => import('@/modules/copytrade/views/list.vue'),
    meta: {
      navActiveLink: 'copytrade',
      pageTitle: 'CopyTrade',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'CopyTrade',
          active: false,
          to: {
            name: 'copytrade',
          },
        },
        {
          text: 'list_copytrade_account',
          active: true,
          to: {
            name: 'copytrade_list',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/copytrade/management',
    name: 'copytrade_management',
    component: () => import('@/modules/copytrade/views/management.vue'),
    meta: {
      navActiveLink: 'copytrade',
      pageTitle: 'CopyTrade',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'CopyTrade',
          active: false,
          to: {
            name: 'copytrade',
          },
        },
        {
          text: 'manage_copytrade_account',
          active: true,
          to: {
            name: 'copytrade_management',
          },
        },
      ],
    },
  },
]
