export default [
  {
    path: '/:lang/document/',
    name: 'document',
    component: () => import('@/views/documents/Document.vue'),
    children: [
      {
        path: 'general',
        name: 'document-general',
        component: () => import('@/views/documents/General.vue'),
        meta: {
          pageTitle: 'Document',
          middleware: 'auth',
          navActiveLink: 'document',
          breadcrumb: [
            {
              text: 'general',
              active: true,
            },
          ],
        },
      },
      {
        path: 'bank',
        name: 'document-bank',
        component: () => import('@/views/documents/BankAccounts.vue'),
        meta: {
          pageTitle: 'Document',
          middleware: 'auth',
          navActiveLink: 'document-general',
          breadcrumb: [
            {
              text: 'bank',
              active: true,
            },
          ],
        },
      },
      {
        path: 'legal',
        name: 'document-legal',
        component: () => import('@/views/documents/LegalCompanies.vue'),
        meta: {
          pageTitle: 'document',
          middleware: 'auth',
          navActiveLink: 'document-general',
          breadcrumb: [
            {
              text: 'legal',
              active: true,
            },
          ],
        },
      },
    ],
  },

]
