import EconomicCalendarApis from '@/services/apis/economic-calendar'

const economicCalendar = new EconomicCalendarApis()
export default {
  state: {
    economic: [],
    countries: [],
  },
  getters: {
    getEconomic: state => state.economic,
    getCountries: state => state.countries,
  },
  mutations: {
    SET_ECONOMIC_CALENDAR(state, economic) {
      const array = []
      Object.entries(economic).forEach(([key, value]) => {
        // eslint-disable-next-line import/no-dynamic-require, global-require, no-param-reassign
        value.flag = require(`@/assets/images/flags/${value.abbreviation}.png`)
        // eslint-disable-next-line import/no-dynamic-require, global-require, no-param-reassign
        value.level = require(`@/assets/images/pages/${value.level}.jpg`)
        if (array.length === 0) {
          array.push({
            mode: 'span', // span means this header will span all columns
            label: value.date, // this is the label that'll be used for the header
            html: false, // if this is true, label will be rendered as html
            children: [
              value,
            ],
          })
        } else {
          Object.entries(array).forEach(([key1, value1]) => {
            if (value1.label === value.date) {
              value1.children.push(value)
            } else {
              array.push({
                mode: 'span', // span means this header will span all columns
                label: value.date, // this is the label that'll be used for the header
                html: false, // if this is true, label will be rendered as html
                children: [
                  value,
                ],
              })
            }
          })
        }
      })
      state.economic = array
    },
    SET_COUNTRIES(state, countries) {
      const object = []
      let src
      Object.entries(countries).forEach(([key, value]) => {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        src = require(`@/assets/images/flags/${value.abbreviation}.png`)
        object.push({ image: src, name: value.name, abbreviation: value.abbreviation })
      })
      state.countries = object
    },
  },
  actions: {
    async getEconomicCalendarData({ commit }) {
      try {
        const response = await economicCalendar.getEconomicCalendarData()
        commit('SET_ECONOMIC_CALENDAR', response.data.economic)
        commit('SET_COUNTRIES', response.data.countries)
        return 200
      } catch (e) {
        return e
      }
    },
  },
}
