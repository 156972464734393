import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/libs/i18n'
import auth from './routes/auth'
import profile from './routes/profile'
import documents from './routes/documents'
import EconomicCalendar from './routes/economic-calendar'
import Dashboard from './routes/dashboard'
import tickets from './routes/tickets'
import FAQ from './routes/FAQ'
import messages from './routes/messages'
import modules from '@/modules'

Vue.use(VueRouter)

const moduleRouters = []
Object.keys(modules).forEach(key => {
  if (modules[key].router) {
    modules[key].router.forEach(item => {
      moduleRouters.push(item)
    })
  }
})
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}/dashboard`,
    },
    {
      path: '/:lang',
      redirect: `/${i18n.locale}/dashboard`,
      name: 'site',
      meta: {
        middleware: 'auth',
      },
      component: {
        render(c) {
          return c('router-view')
        },
      },
      children: [
        ...moduleRouters,
        ...auth,
        ...profile,
        ...documents,
        ...EconomicCalendar,
        ...Dashboard,
        ...tickets,
        ...FAQ,
        ...messages,
        {
          path: '/:lang/customer-wallets/deposit/online/status',
          name: 'deposit-online-status',
          component: () => import('@/views/pages/onlineDepositStatus.vue'),
          meta: {
            navActiveLink: 'deposit',
            route: 'deposit-online-status',
            pageTitle: 'deposit_online_status',
          },
        },
        {
          path: '/:lang/error-404',
          name: 'error-404',
          component: () => import('@/views/error/Error404.vue'),
          meta: {
            middleware: 'both',
            layout: 'full',
          },
        },
        {
          path: '/:lang/maintenance',
          name: 'maintenance',
          component: () => import('@/views/error/maintenance.vue'),
          meta: {
            pageTitle: 'maintenance',
            middleware: 'both',
            layout: 'full',
          },
        },
        {
          path: '/:lang/logout',
          name: 'logout',
          meta: {
            middleware: 'auth',
            layout: 'full',
          },
        },
        {
          path: '*',
          redirect: '/:lang/error-404',
        },
      ],
    },
  ],
})

export default router
