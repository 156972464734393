import mock from '@/fake-db/mock'

const data = {
  economic: [
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'high',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'low',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'medium',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'medium',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'low',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2022:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'high',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
    {
      date: '2019:02:11',
      time: '22:15',
      currency: 'IRR',
      abbreviation: 'IR',
      event: 'New Year\'s Day',
      level: 'low',
      actual: '18.3%',
      forecast: '45.0%',
      previous: '43.6%',
    },
  ],
  countries: [
    { abbreviation: 'AF', name: 'Afghanistan' },
    { abbreviation: 'AX', name: 'Aland Islands' },
    { abbreviation: 'AL', name: 'Albania' },
    { abbreviation: 'DZ', name: 'Algeria' },
    { abbreviation: 'AS', name: 'American Samoa' },
    { abbreviation: 'AD', name: 'Andorra' },
  ],
}

mock.onGet('economic-calendar').reply(() => [200, data])
