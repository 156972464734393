import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
if (!process.env.VUE_APP_ACTIVE_LANGUAGE) {
  console.error('VUE_APP_ACTIVE_LANGUAGE env var is undefined')
}
const activeLocales = process.env.VUE_APP_ACTIVE_LANGUAGE.split(',')
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  messages: loadLocaleMessages(),
})

function prepareLocales() {
  const locs = []
  activeLocales.forEach(activeLoc => {
    if (activeLoc === 'fa') {
      locs.push({
        locale: 'fa',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/flags/fa.png'),
        name: 'Persian',
      })
    }
    if (activeLoc === 'en') {
      locs.push({
        locale: 'en',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      })
    }
  })
  return locs
}

i18n.locales = prepareLocales()

export default i18n
