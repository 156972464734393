export default [
  {
    path: '/:lang/invest/quick-register',
    name: 'investModule-quick-register',
    component: () => import('@/modules/invest/views/quickRegisterLanding.vue'),
    meta: {
      noAuthStep: true,
      navActiveLink: 'Invest',
      pageTitle: 'invest',
      middleware: 'guest',
      layout: 'full',
      breadcrumb: [
        {
          text: 'invest',
          active: false,
        },
        {
          text: 'invest-plans',
          active: true,
          to: {
            name: 'investModule-plans',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/invest/my-plan',
    name: 'investModule-my-plan',
    component: () => import('@/modules/invest/views/myPlan.vue'),
    meta: {
      noAuthStep: true,
      navActiveLink: 'Invest',
      pageTitle: 'invest',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'invest',
          active: false,
        },
        {
          text: 'invest-plans',
          active: true,
          to: {
            name: 'investModule-plans',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/invest/plans',
    name: 'investModule-plans',
    component: () => import('@/modules/invest/views/plans.vue'),
    meta: {
      noAuthStep: true,
      navActiveLink: 'Invest',
      pageTitle: 'invest',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'invest',
          active: false,
        },
        {
          text: 'invest-plans',
          active: true,
          to: {
            name: 'investModule-plans',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/invest/select-plan-type',
    name: 'investModule-select-plan-type',
    component: () => import('@/modules/invest/views/planSelectType.vue'),
    meta: {
      noAuthStep: true,
      navActiveLink: 'investModule-deposit',
      pageTitle: 'deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
        },
        {
          text: 'investModule-select-plan-type',
          active: true,
          to: {
            name: 'investModule-select-plan-type',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/invest/deposit/:type',
    name: 'investModule-deposit',
    component: () => import('@/modules/invest/views/deposit.vue'),
    meta: {
      noAuthStep: true,
      navActiveLink: 'investModule-select-plan-type',
      pageTitle: 'deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
        },
        {
          text: 'investModule-deposit',
          active: true,
          to: {
            name: 'investModule-deposit',
          },
        },
      ],
    },
  },
  // {
  //   path: '/:lang/invest/wire-deposit/:type',
  //   name: 'investModule-wire-deposit',
  //   component: () => import('@/modules/invest/views/wireDeposit.vue'),
  //   meta: {
  //     noAuthStep: true,
  //     navActiveLink: 'investModule-deposit',
  //     pageTitle: 'wire-deposit',
  //     middleware: 'auth',
  //     breadcrumb: [
  //       {
  //         text: 'wire-deposit',
  //         active: false,
  //       },
  //       {
  //         text: 'investModule-wire-deposit',
  //         active: true,
  //         to: {
  //           name: 'investModule-wire-deposit',
  //         },
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/:lang/invest/crypto-deposit/:type',
  //   name: 'investModule-crypto-deposit',
  //   component: () => import('@/modules/invest/views/cryptoDeposit.vue'),
  //   meta: {
  //     noAuthStep: true,
  //     navActiveLink: 'investModule-deposit',
  //     pageTitle: 'crypto-deposit',
  //     middleware: 'auth',
  //     breadcrumb: [
  //       {
  //         text: 'crypto-deposit',
  //         active: false,
  //       },
  //       {
  //         text: 'investModule-crypto-deposit',
  //         active: true,
  //         to: {
  //           name: 'investModule-crypto-deposit',
  //         },
  //       },
  //     ],
  //   },
  // },
]
