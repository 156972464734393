export default [
  {
    path: '/:lang/customer-wallets',
    name: 'customer-wallet',
    component: () => import('@/modules/crypto/views/customer-wallets/CustomerWallet.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'customer-wallet',
      pageTitle: 'Customer Wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Customer Wallet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/crypto-wallets',
    name: 'crypto-wallets',
    component: () => import('@/modules/crypto/views/components/wallet/CryptoWallets.vue'),
    meta: {
      navActiveLink: 'Crypto',
      route: 'customer-wallet',
      pageTitle: 'Crypto Wallets',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/deposit',
    name: 'deposit',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/Deposit.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'deposit',
      pageTitle: 'deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/withdraw',
    name: 'withdraw',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/Withdraw.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'withdraw',
      pageTitle: 'withdrawal',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Withdraw',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/deposit',
    name: 'fiat-deposit',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/FiatDeposit.vue'),
    meta: {
      navActiveLink: 'deposit',
      route: 'fiat-deposit',
      pageTitle: 'fiat_Deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'deposit',
          },

        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/deposit/online/status',
    name: 'fiat-deposit-online-status',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/onlineDepositStatus.vue'),
    meta: {
      navActiveLink: 'deposit',
      route: 'fiat-deposit-online-status',
      pageTitle: 'fiat_deposit_online_status',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'deposit',
          },

        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/deposit/online/:token?',
    name: 'fiat-deposit-online',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/onlineFiatDeposit.vue'),
    meta: {
      navActiveLink: 'deposit',
      route: 'fiat-deposit-online',
      pageTitle: 'fiat_deposit_online',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'deposit',
          },

        },
      ],
    },
  },

  {
    path: '/:lang/customer-wallets/fiat/withdraw/:token?',
    name: 'fiat-withdraw',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/FiatWithdraw.vue'),
    meta: {
      navActiveLink: 'withdraw',
      pageTitle: 'fiat_withdraw',
      route: 'fiat-withdraw',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'withdraw-pageTitle',
          active: false,
          to: {
            name: 'withdraw',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/Invoice',
    name: 'Invoice',
    component: () => import('@/modules/crypto/views/customer-wallets/fiat/FiatInvoice.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      pageTitle: 'Customer Wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/crypto-deposit/:coin_name/:coin_symbol',
    name: 'cryptoDeposit',
    component: () => import('@/modules/crypto/views/customer-wallets/cryptoDeposit.vue'),
    meta: {
      navActiveLink: 'crypto-wallets',
      pageTitle: 'Crypto Deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: false,
          to: {
            name: 'crypto-wallets',
          },
        },
        {
          text: 'Deposit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/crypto-withdraw/:coin_name/:coin_symbol',
    name: 'cryptoWithdraw',
    component: () => import('@/modules/crypto/views/customer-wallets/cryptoWithdraw.vue'),
    meta: {
      navActiveLink: 'crypto-wallets',
      pageTitle: 'Crypto Withdraw',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: false,
          to: {
            name: 'crypto-wallets',
          },
        },
        {
          text: 'Withdraw',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/deposit-withdraw-wall',
    name: 'deposit-withdraw-wall',
    component: () => import('@/modules/crypto/views/customer-wallets/DepositWithdrawWall.vue'),
    meta: {
      pageTitle: 'Deposit Withdraw Wall',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Deposit Withdraw Wall',
          active: true,
        },
      ],
    },
  },
]
