import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { BIcon } from 'bootstrap-vue'
import showErrorMessages from '@/mixins/showErrorMessages'

Vue.mixin(showErrorMessages)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('BIcon', BIcon)
Vue.component(FeatherIcon.name, FeatherIcon)
