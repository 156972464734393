import {
  BIconArrowLeftRight, BIconCartDash, BIconCartPlus, BIconWallet2,
} from 'bootstrap-vue'
import { CircleIcon } from 'vue-feather-icons'

export default [{
  title: 'crypto',
  icon: CircleIcon,
  children: [
    {
      title: 'WalletList',
      route: 'crypto-wallets',
      icon: BIconWallet2,
    },
    {
      title: 'deposit',
      route: 'deposit',
      icon: BIconCartPlus,
    },
    {
      title: 'withdrawal',
      route: 'withdraw',
      icon: BIconCartDash,
    },
    {
      title: 'exchange',
      icon: BIconArrowLeftRight,
      href: process.env.VUE_APP_EXCHANGE_ADDRESS,
    },
  ]
  ,
}]
