// eslint-disable-next-line import/prefer-default-export
export const objectToUrlParam = obj => {
  const str = []
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  }
  return `?${str.join('&')}`
}

export const getBrandName = langSymbol => {
  const nameLang = {}
  process.env.VUE_APP_BRAND_NAME.split(',').forEach(nl => {
    const [lang, name] = nl.split(':')
    nameLang[lang.trim()] = name.trim()
  })
  return nameLang[langSymbol]
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d)(\.))/g, ',')
}
