export default [
  {
    path: '/:lang/ib',
    component: () => import('@/modules/IB/views/router.vue'),
    children: [
      {
        path: 'dashbord',
        name: 'ib-dashbord',
        component: () => import('@/modules/IB/views/Dashboard.vue'),
        meta: {
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'dashboard',
              active: true,
            },
          ],
        },
      },
      {
        path: 'referral',
        name: 'ib-referral',
        component: () => import('@/modules/IB/views/referral.vue'),
        meta: {
          navActiveLink: 'ib-dashbord',
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'referral',
              active: true,
            },
          ],
        },
      },
      {
        path: 'commissions',
        name: 'ib-commissions',
        component: () => import('@/modules/IB/views/commissions.vue'),
        meta: {
          navActiveLink: 'ib-dashbord',
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'commissions',
              active: true,
            },
          ],
        },
      },
      {
        path: 'register',
        name: 'ib-register',
        component: () => import('@/modules/IB/views/Register.vue'),
        meta: {
          navActiveLink: 'ib-dashbord',
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'register',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clients/list',
        name: 'ib-clients-list',
        component: () => import('@/modules/IB/views/members/index.vue'),
        meta: {
          navActiveLink: 'ib-dashbord',
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'ib.clients-list',
              active: true,
            },
          ],
        },
      },
      {
        path: 'banner',
        name: 'ib-banner',
        component: () => import('@/modules/IB/views/Advertising.vue'),
        meta: {
          pageTitle: 'Ib-pannel',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client/details/:userType/:userID',
        name: 'ib-client-details',
        component: () => import('@/modules/IB/views/members/UserDetails.vue'),
        meta: {
          pageTitle: 'ib.client-details',
          middleware: 'auth',
          navActiveLink: 'ib-dashbord',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },
            },
            {
              text: 'ib.client-details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clients/accounts/:userType/:userID',
        name: 'ib-clients-accounts',
        component: () => import('@/modules/IB/views/members/MetatraderAccounts.vue'),
        meta: {
          pageTitle: 'ib.clients-accounts',
          middleware: 'auth',
          navActiveLink: 'ib-dashbord',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },

            },
            {
              text: 'ib.clients-accounts',
              active: true,
            },

          ],
        },
      },
      {
        path: 'clients/demo-accounts',
        name: 'ib-clients-demo-accounts',
        component: () => import('@/modules/IB/views/members/forexDemoAcounts.vue'),
        meta: {
          pageTitle: 'ib.clients-demo-accounts',
          middleware: 'auth',
          navActiveLink: 'ib-dashbord',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },

            },
            {
              text: 'ib.clients-demo-accounts',
              active: true,
            },

          ],
        },
      },
      {
        path: 'clients/metatrader-accounts-history/:userType/:userID/:accid',
        name: 'ib-metatrader-accounts-history',
        component: () => import('@/modules/IB/views/members/MetatraderAccountHistory.vue'),
        meta: {
          pageTitle: 'ib.clients-accounts',
          navActiveLink: 'ib-dashbord',
          middleware: 'auth',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },
            },
            {
              text: 'ib.clients-accounts',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clinet/trade/history/:userType/:userID/:market',
        name: 'ib-client-trade-history',
        component: () => import('@/modules/IB/views/members/tradeHistory.vue'),
        meta: {
          pageTitle: 'ib.client-forex-trade-history',
          navActiveLink: 'ib-dashbord',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },
            },
            {
              text: 'ib.client-forex-trade-history',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clinet/money/transfer/history/:userType/:userID',
        name: 'ib-client-money-transfer-history',
        component: () => import('@/modules/IB/views/members/MoneyTransferHistory.vue'),
        meta: {
          pageTitle: 'ib.client-money-transfer-history',
          navActiveLink: 'ib-dashbord',
          breadcrumb: [
            {
              text: 'Ib-pannel',
              active: false,
              to: {
                name: 'ib-dashbord',
              },
            },
            {
              text: 'ib.client-money-transfer-history',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
