export default [
  {
    path: '/:lang/messages',
    name: 'messages',
    component: () => import('@/views/messages/index.vue'),
    meta: {
      pageTitle: 'Messages',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
          to: {
            name: 'messages',
          },
        },
      ],
    },
  },
]
