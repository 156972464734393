export default {
  state: {
    profileInfo: {
      citizenship: '',
      active: '',

    },
  },
  getters: {
    profileInfo: state => state.profileInfo,
  },
  mutations: {
    SET_USER_INFO(state, generalInfo) {
      state.profileInfo = generalInfo
    },
  },

}
