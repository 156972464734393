import { $themeConfig } from '@themeConfig'
import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'

// eslint-disable-next-line import/prefer-default-export
export const setLang = lang => {
  i18n.locale = lang
  if (lang === 'fa' || lang === 'ar') {
    $themeConfig.layout.isRTL = true
    i18n.isRTL = true
    document.documentElement.setAttribute('dir', 'rtl')
    store.state.appConfig.layout.isRTL = true
  } else {
    $themeConfig.layout.isRTL = false
    i18n.isRTL = false
    document.documentElement.setAttribute('dir', 'ltr')
    store.state.appConfig.layout.isRTL = false
  }
}

export const changeLang = lang => {
  localStorage.setItem('lang', lang)
  router.push({ params: { lang } })
}
