import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class DocumentApis {
    saveDocumentGeneral = async data => await axiosIns.post('document/general/action', data)

    saveDocumentResidency= async data => await axiosIns.post('document/residency/action', data)

    saveDocumentBankAccounts =async data => await axiosIns.post('document/bank/action', data)

    saveDocumentLegal= async data => await axiosIns.post('document/legal/action', data)

    getDocumentInfo = async () => await axiosIns.get('document/general/all')

    getDocumentGeneralInfo = async () => await axiosIns.get('document/general')

    getDocumentResidencyInfo = async () => await axiosIns.get('document/residency')

    getDocumentBankInfo = async data => await axiosIns.get(`document/bank${objectToUrlParam(data)}`)

    getDocumentLegalInfo = async () => await axiosIns.get('document/legal')
}
