import {
  CopyIcon, PlusIcon, ListIcon, ActivityIcon,
} from 'vue-feather-icons'

export default [{
  title: 'CopyTrade',
  icon: CopyIcon,
  route: 'copytrade',
  children: [
    {
      title: 'account_management',
      route: 'copytrade_management',
      icon: ActivityIcon,
    },
    {
      title: 'account_create',
      route: 'copytrade_create',
      icon: PlusIcon,
    },
    {
      title: 'list_copytrade_account',
      route: 'copytrade_list',
      icon: ListIcon,
    },
  ],
}]
